<template>
  <div class="centerwrap">
    <div class="centerbox">
      <el-menu router :default-active="$route.path" class="" mode="horizontal">
        <el-menu-item index="/order">交易列表</el-menu-item>
      </el-menu>
      <div class="topbar">
        <span class="money">账号余额：<span>{{money}} </span>元 </span>
        <el-button type="primary" size="mini" @click="handelBlance">提现</el-button>
        <el-button type="primary" plain size="mini"><router-link :to="{name:'orderBandBankcard'}" tag="span">绑定提现银行卡</router-link> </el-button>

        <span class="moneyorg">提现最小金额为￥100.00</span>
        <router-link
          tag="span"
          :to="{ name: 'orderWithdrawal' }"
          class="txlist"
        >
          <el-button type="primary" plain size="mini">提现列表</el-button>
        </router-link>
      </div>
      <el-table :data="listData" stripe size="small" style="width: 100%">
        <el-table-column prop="OTypename" label="类型"> </el-table-column>
        <el-table-column prop="Product" label="产品"> </el-table-column>
        <el-table-column prop="Ordertime" label="日期" width="180">
        </el-table-column>
        <el-table-column prop="Buyuser" label="操作人"> </el-table-column>
        <el-table-column prop="Money" label="金额（元）"> </el-table-column>
        <el-table-column prop="OrderStatus" label="状态">
          <template slot-scope="scope">
            <span
              :class="
                scope.row.Status == 1
                  ? 'green'
                  : scope.row.Status == 2
                  ? 'org'
                  : 'grey'
              "
              >{{ scope.row.OrderStatus }}</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="Status" label=" " width="90">
          <template slot-scope="scope">
            <el-button v-if="scope.row.Status == 2 && scope.row.OType == 1" type="primary" size="mini"
                @click="payAlipay(scope.row.Orderno)">去支付</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        v-if="total > pageCount"
        @current-change="handleCurrentChange"
        layout="prev, pager, next"
        :page-size.sync="pageCount"
        :current-page="currentPage"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import { getOrderRequest,getWithdrawBalanceRequest,withdrawRequest,payAlipayRequest } from "@/api/project.js";
export default {
  data() {
    return {
      listData: [],
      currentPage: 1,
      total: "",
      pageCount: 10,
      money:''
    };
  },
  created() {
    this.getOrderRequest();
    this.getWithdrawBalanceRequest();
  },
  methods: {
    showtext(e) {
      e.currentTarget.className = "showtext";
    },
    hidetext(e) {
      e.currentTarget.className = "";
    },
    handleClick(row) {
      console.log(row);
    },
    async getOrderRequest(val) {
      try {
        val = val || "1";
        const result = await getOrderRequest({
          page: val,
        });
        if (result.status == 200) {
          this.listData = result.data.list;
          this.total = result.data.totalCount;
          this.pageCount = result.data.pageCount;
        }
      } catch (err) {}
    },
    async withdrawRequest(value) {
      try {
        const result = await withdrawRequest({
          money:value,
        });
        if (result.status == 200) {
         this.$message({
            type: 'success',
            message:  result.message ||'成功提现' +value+' 元'
          });
       }else{
          this.$message({
            type: 'info',
            message: result.message ||'提现失败'
          });  
        }
      } catch (err) {}
    },
    async getWithdrawBalanceRequest() {
      try {
        const result = await getWithdrawBalanceRequest();
        if (result.status == 200) {
          this.money = result.data.balance;
        }
      } catch (err) {}
    },
    handleCurrentChange(val) {
      this.getOrderRequest(val);
    },
    handelBlance(){
       this.$prompt('请输入提现金额', `可提现金额 ${this.money} 元`, {
          confirmButtonText: '提交',
          cancelButtonText: '取消',
          center:true,
          inputValidator:(value) => {  
            if(value<100){
              return '提现最小金额是100';
            }
             if(value>this.money) {//对输入内容校验
                  return '可提现金额不足';
             }
          },
        }).then(({ value }) => {
         this.withdrawRequest(value)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '取消提现'
          });       
        });
    },
    async payAlipay(Orderno) {
      try {
        const result = await payAlipayRequest({
          orderno: Orderno,
        });
        if (result.status == 200) {
          this.payUrl = result.data.payUrl;
          window.open(this.payUrl);
        }
      } catch (err) {}
    },
  },
};
</script>
<style  lang="less" scoped>
.centerwrap {
  position: relative;
  padding: 0 20px;
}
.centerbox {
  width: 100%;
}

.org {
  color: #ed8b02;
}
.green {
  color: #009701;
}
.grey {
  color: #bdbdbd;
}
.topbar {
  background: #e5f4ff;
  border-radius: 4px;
  padding: 0 15px;
  margin: 20px 0;
  font-size: 14px;
  color: #666;
  line-height: 50px;
  .money {
    margin-right: 15px;
    span {
      font-size: 26px;
      color: #000;
    }
  }
  .moneyorg {
    color: #ed8b02;
    font-size: 12px;
    margin: 0 10px;
  }
  .txlist {
    float: right;
  }
}
.el-pagination {
  text-align: right;
  margin: 20px;
}
/deep/.el-menu--horizontal {
  // padding: 10px 0 0 0;
  & > .el-menu-item {
    border-bottom: 2px solid #16aed0;
    padding: 4px 10px;
    height: 46px;
    line-height: 32px;
    font-size: 14px;
    color: #222;
    margin-right: 20px;
    &.is-active {
      color: #16aed0;
    }
  }
}
</style>